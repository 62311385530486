<template>
  <section class="main-cards-block">
    <div class="main-cards-block__wrapper ui-container">
      <div class="main-cards-block__text">
        <h2
          class="main-cards-block__title h h--2"
          v-html="title"
        />

        <p
          v-if="description"
          class="main-cards-block__desc p p--2"
          v-html="description"
        />
      </div>

      <ul
        v-if="tabs"
        class="main-cards-block__tabs"
      >
        <li
          v-for="(tab, i) in tabs"
          :key="i"
          class="main-cards-block__tabs-item"
        >
          <UiSegmentButton
            size="small"
            size-mode="big-lg"
            :is-active="activeTabIndex === i"
            @click="handleSegmentButtonClick(i, tab)"
          >
            {{ tab.title }}
          </UiSegmentButton>
        </li>
      </ul>

      <ul class="main-cards-block__list">
        <li
          v-for="(card, i) in cards"
          :key="i"
          class="main-cards-block__item"
        >
          <ProductCardSmart
            v-if="card"
            :card="card"
            class="main-cards-block__card"
            @click="emit('click:card', getCardData(card, i))"
            @visible="emit('visible:card', getCardData(card, i))"
          />
        </li>
      </ul>

      <UiButtonLink
        :link="buttonLink"
        size="extra-big"
        stretch-mode="md-max"
        theme="quaternary"
        class="main-cards-block__button"
        @click="emit('click:link')"
      >
        {{ buttonText }}
      </UiButtonLink>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import type { Card } from '@academica-box/components/Cards/UiProductCard/types'
  import UiButtonLink from '@academica-box/components/Links/UiButtonLink/UiButtonLink.vue'
  import UiSegmentButton from '@academica-box/components/Buttons/UiSegmentButton/UiSegmentButton.vue'
  import ProductCardSmart from '@/components/ProductCard.smart/ProductCard.smart.vue'

  type Tab = {
    title: string;
    slug: string;
  }

  withDefaults(defineProps<{
    title: string;
    description?: string;
    tabs?: Tab[];
    cards: Card[];
    buttonLink: string;
    buttonText: string;
  }>(), {
    description: undefined,
    tabs: undefined,
  })

  const activeTabIndex = ref(0)

  const emit = defineEmits(['click', 'click:link', 'click:card', 'visible:card'])

  const getCardData = (card: Card, index: number) => {
    return {
      ...card,
      nomenclatureType: card.isFree ? 'free' : 'paid',
      cardNumber: index + 1
    }
  }

  const handleSegmentButtonClick = (index: number, tab: Tab) => {
    if (activeTabIndex.value === index) {
      return
    }

    activeTabIndex.value = index

    emit('click', tab)
  }
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/combinations/media-reset-z' as *;
  @use '@academica-box/styles/UiContainer/variables' as *;

  .main-cards-block__wrapper {
    @include media(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .main-cards-block__text {
    margin-bottom: 32px;

    @include media(lg) {
      width: 740px;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }

  .main-cards-block__title {
    margin-bottom: 0;
  }

  .main-cards-block__desc {
    margin-top: 8px;
    color: var(--text-secondary-color);

    @include media(lg) {
      margin-top: 12px;
    }
  }

  .main-cards-block__tabs {
    @include ulres;

    display: flex;
    margin-bottom: 24px;

    @include media(sm-max) {
      flex-wrap: nowrap;
      margin-right: -20px;
      margin-left: -20px;
      padding-left: 20px;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    @include media(lg) {
      margin-right: auto;
      margin-bottom: 32px;
    }

    // фикс для Сафари
    &::after {
      @include media(sm-max) {
        padding-right: 20px;
        content: '';
      }
    }

    &::-webkit-scrollbar {
      @include media(sm-max) {
        display: none;
      }
    }
  }

  .main-cards-block__tabs-item {
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .main-cards-block__list {
    @include ulres;

    display: flex;
    margin-right: -20px;
    margin-bottom: 32px;
    margin-left: -20px;
    padding-left: 20px;

    @include media(md, md-max) {
      margin-right: calc((100vw - #{$ui-md-c}) / -2);
      margin-left: calc((100vw - #{$ui-md-c}) / -2);
      padding-left: calc((100vw - #{$ui-md-c}) / 2);
    }

    @include media(md-max) {
      flex-wrap: nowrap;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    @include media(lg) {
      margin-right: auto;
    }

    // фикс для Сафари
    &::after {
      padding-right: 20px;

      @include media(md) {
        padding-right: 36px;
      }

      @include media(md-max) {
        content: '';
      }
    }

    &::-webkit-scrollbar {
      @include media(md-max) {
        display: none;
      }
    }
  }

  .main-cards-block__item {
    display: none;
    flex-shrink: 0;
    width: 260px;

    @include media(md-max) {
      display: block;
    }

    @include media(lg) {
      width: 288px;
    }

    @include media(xl) {
      width: 320px;
    }

    &:not(:last-child) {
      margin-right: 6px;

      @include media(lg) {
        margin-right: 32px;
      }
    }

    &:nth-child(-n + 3) {
      @include media(lg) {
        display: block;
      }
    }

    &:nth-child(-n + 4) {
      @include media(xxl) {
        display: block;
      }
    }
  }

  .main-cards-block__card {
    height: 100%;
  }
</style>
